import React from "react";
import styled from "styled-components";
import useScript from "../../hooks/useScript";

const AirtableBareForm = styled.iframe`
    background: transparent;
    border: 0;
`

const AirtableForm = ({ formId, prefill, ...rest }) => {
    useScript('https://static.airtable.com/js/embed/embed_snippet_v1.js');

    let queryParams = [
        "backgroundColor=red",
    ];
    if (prefill) {
        const keys = Object.keys(prefill)
        for (const key of keys) {
            if (prefill.hasOwnProperty(key)) {
                let escapedKey = key.replace(/\s/g, "+");
                queryParams.push("prefill_" + escapedKey + "=" + prefill[key]);
            }
        }
    }
    const src = "https://airtable.com/embed/" + formId + "?" + queryParams.join("&");

    return (
        <>
            <AirtableBareForm
                className="airtable-embed airtable-dynamic-height"
                frameBorder="0"
                onmousewheel="" width="100%" height="1396"
                src={src}
                {...rest} />
        </>
    );
};

export default AirtableForm;
