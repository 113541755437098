import React from "react";
import { Section } from "../../components/Core";
import { Container } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import AirtableForm from "../../components/AirtableForm";

const CreateSubmissionPage = () => {
    return (
        <>
            <PageWrapper>
                <Section>
                    <Container
                        fluid
                        css={`
                          padding-left: 0;
                          padding-right: 0;
                        `}
                    >
                        <AirtableForm
                            formId="shrF5fKFrXc4gI764"
                        />
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default CreateSubmissionPage;
